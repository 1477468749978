export const validateCreditCardNumber = (value, stopTyping = false) => {
  let valid = false;

  const msg = {
    req: "Credit card number is required.",
    len: "Invalid credit card length.",
    err: "Invalid credit card number.",
  };

  if (!value) return { valid, error: msg.req, stopTyping };

  const digits = value.replace(/\D/g, "");
  const digitLen = digits.length;

  // Get if card is AMEX or not
  const AMEX_NUM = 3;
  const isAMEX = digits.startsWith(AMEX_NUM);
  const correctLength = isAMEX ? 15 : 16;

  // Check not more than 16 digits and luhn algorithm
  if (digitLen !== correctLength) return { valid, error: msg.len, stopTyping };
  if (!luhnCheck(digits)) return { valid, error: msg.err, stopTyping };

  return { valid: true, error: null, isAMEX, stopTyping: true };
};

const luhnCheck = (num) => {
  let arr = Array.from(num)
    .reverse()
    .map((x) => parseInt(x));
  let lastDigit = arr.splice(0, 1)[0];
  let sum = arr.reduce((acc, val, i) => {
    if (i % 2 === 0) {
      val *= 2;
      if (val > 9) val -= 9;
    }
    return acc + val;
  }, 0);
  sum += lastDigit;

  return sum % 10 === 0;
};

export const validateName = (value) => {
  if (value.trim().length === 0)
    return { valid: false, error: "Name on card is required." };

  return { valid: true, error: null };
};

export const validateExpiry = (value) => {
  // if (!value) return { valid: false, error: "Expiry date is required." };

  // let [month, year] = value.split("/");
  // if (!month || !year) {
  //   return {
  //     valid: false,
  //     error: "Expiry date is in an incorrect format.",
  //   };
  // }

  // if (!expiryCheck(month, year)) {
  //   return { valid: false, error: "Credit card has expired." };
  // }

  return { valid: true, error: null };
};

const expiryCheck = (month, year) => {
  console.log("Checking expiry");
  let expiryDate = new Date(year, month); // Set to first day of the month following the expiry month
  let currentDate = new Date();

  // Set currentDate to the last day of the previous month
  currentDate.setDate(0);

  return expiryDate > currentDate;
};

export const validateCVC = (value) => {
  const cvcLength = value.length;
  if (cvcLength !== 3 && cvcLength !== 4)
    return { valid: false, error: "Invalid CVC." };

  return { valid: true, error: null };
};

export const validateForm = (formData) => {
  const errors = {};

  const { valid: ccNumValid, error: ccNumError } = validateCreditCardNumber(
    formData.number
  );
  if (!ccNumValid) errors.number = ccNumError;

  const { valid: nameValid, error: nameError } = validateName(formData.name);
  if (!nameValid) errors.name = nameError;

  const { valid: expiryValid, error: expiryError } = validateExpiry(
    formData.expiry
  );
  if (!expiryValid) errors.expiry = expiryError;

  const { valid: cvcValid, error: cvcError } = validateCVC(formData.cvc);
  if (!cvcValid) errors.cvc = cvcError;

  return errors;
};
