import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./logs.css";

import axios from "axios";
import MUIDataTable from "mui-datatables";
import IconButton from "@mui/material/IconButton";
import RefreshIcon from "@mui/icons-material/Refresh";
import { utcToZonedTime, format } from "date-fns-tz";

const cstFormat = "MM/dd/yyyy hh:mm:ss a";
const cstTimeZone = "America/Chicago"; // Central Standard Time
const formatDate = (date) => {
  const cstDate = utcToZonedTime(date, cstTimeZone);
  const formattedDate = format(cstDate, cstFormat, { timeZone: cstTimeZone });

  return formattedDate;
};

const columns = [
  {
    name: "userName",
    label: "User Name",
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: "status",
    label: "Status",
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: "function",
    label: "Function",
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: "activity",
    label: "Activity",
    options: {
      filter: false,
      sort: true,
    },
  },
  {
    name: "customer_number",
    label: "Customer",
    options: {
      filter: false,
      sort: true,
    },
  },
  {
    name: "created_at",
    label: "Created At",
    options: {
      filter: false,
      sort: true,
      customBodyRender: (value) => {
        return formatDate(value);
      },
    },
  },
];

const CustomToolbar = (props) => {
  return (
    <IconButton onClick={props.refreshLogs}>
      <RefreshIcon />
    </IconButton>
  );
};

const Logs = () => {
  const [logs, setLogs] = useState([]);
  const [isLoading, setIsLoading] = useState(true); // <-- Initialize as true
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("token");
    axios
      .get("https://lb.fshac.com:8443/api/logs", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setLogs(res.data);
        setIsLoading(false); // <-- Set to false on success
      })
      .catch((err) => {
        console.error(err);
        setIsLoading(false); // <-- Also set to false on error
      });
  }, []);

  const refreshLogs = () => {
    setIsLoading(true); // Set loading to true as we start fetching
    const token = localStorage.getItem("token");
    axios
      .get("https://lb.fshac.com:8443/api/logs", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setLogs(res.data);
        setIsLoading(false); // <-- Set to false on success
      })
      .catch((err) => {
        console.error(err);
        setIsLoading(false); // <-- Also set to false on error
      });
  };

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      navigate("/login");
    }
  }, [navigate]);

  const options = {
    filterType: "dropdown",
    responsive: "standard",
    selectableRows: "none", // Remove checkboxes
    viewColumns: false,
    download: true,
    print: false,
    setTableProps: () => {
      return {
        style: {
          width: "100%",
        },
      };
    },
    customToolbar: () => {
      return <CustomToolbar refreshLogs={refreshLogs} />;
    },
  };

  console.log(logs);

  return isLoading ? (
    <div>Loading...</div>
  ) : (
    <div className="mui-custom-table-contain">
      <MUIDataTable
        className="full-width-table"
        title={"Log Report"}
        data={logs}
        columns={columns}
        options={options}
      />
    </div>
  );
};

export default Logs;
