import React, { useState, useCallback, useEffect } from "react";
import { Form, Row, Col } from "react-bootstrap";
import Cards from "react-credit-cards-2";
import "react-credit-cards-2/dist/es/styles-compiled.css";
import "./styles.css";

import {
  validateCreditCardNumber,
  validateName,
  validateExpiry,
  validateCVC,
  validateForm,
} from "./validate.js";

import { getFormattedExpiry, getFormattedCreditCardNumber } from "./format.js";

const isFormValid = (formData) => {
  const isNameValid = validateName(formData.name).valid;
  const isNumberValid = validateCreditCardNumber(formData.number).valid;
  const isExpiryValid = validateExpiry(formData.expiry).valid;
  const isCVCValid = validateCVC(formData.cvc).valid;

  return isNameValid && isNumberValid && isExpiryValid && isCVCValid;
};

const CreditCardForm = ({ onChange, clearForm }) => {
  const [state, setState] = useState({
    isValid: false,

    name: "",
    nameValid: { valid: true },

    number: "",
    numberValid: { valid: true },

    cvc: "",
    cvcValid: { valid: true },

    expiry: "",
    expiryValid: { valid: true },

    focus: "",
  });
  const [tempExpiry, setTempExpiry] = useState("");

  useEffect(() => {
    if (clearForm) {
      setState({
        isValid: false,
        name: "",
        nameValid: { valid: true },
        number: "",
        numberValid: { valid: true },
        cvc: "",
        cvcValid: { valid: true },
        expiry: "",
        expiryValid: { valid: true },
        focus: "",
      });

      setTempExpiry("");
    }
  }, [clearForm]);

  // const [dataUpdated, setDataUpdated] = useState(false);

  const handleInputChange = (evt) => {
    const { name, value } = evt.target;
    console.log("INPUT CHANGED! ", name, value);

    let validationResult = true;
    let formattedValue = value;

    switch (name) {
      case "number":
        if (
          value.length < state.number.length ||
          /^(?=.*\d)[\d ]+$/.test(value)
        ) {
          validationResult = validateCreditCardNumber(value);
          if (!validationResult.error) {
            formattedValue = getFormattedCreditCardNumber(value);
          } else {
            formattedValue = value.replace(/\D/g, "");
          }

          if (value.replace(/\D/g, "").length > 16) return;
          if (value.startsWith("3") && value.replace(/\D/g, "").length > 15)
            return;
        } else {
          return;
        }
        break;

      case "name":
        if (value && !/^[a-zA-Z\-. ]+$/.test(value)) return; // Allow a-z, A-Z, hyphens, and spaces
        if (value.length < state.name.length || value.length <= 45) {
          validationResult = validateName(value);
        } else {
          return;
        }
        break;

      case "expiry":
        const hasSingleDash = (value.match(/-/g) || []).length === 1;
        const hasSingleSlash = (value.match(/\//g) || []).length === 1;
        const isNumbersOnly = /^\d+$/.test(value);

        // Ensuring the input has only numbers or matches the format with single separator
        if (!isNumbersOnly && !/^(?=.*\d)[\d/-]+$/.test(value)) return;

        // Check if there is only one of either separators, but not both
        if (
          (hasSingleDash && hasSingleSlash) ||
          (value.match(/-/g) || []).length > 1 ||
          (value.match(/\//g) || []).length > 1
        )
          return;

        if (value.replace(/\D/g, "").length > 6) return;
        if (value.length > 7) return;

        formattedValue =
          getFormattedExpiry(evt.target.value) || evt.target.value;
        validationResult = validateExpiry(formattedValue);
        setTempExpiry(formattedValue);
        break;

      case "cvc":
        if (value.length < state.cvc.length || /^(?=.*\d)[\d]+$/.test(value)) {
          validationResult = validateCVC(value);

          if (value.length > 4) return;
          if (!state.number) break;
          if (!state.number.startsWith("3") && value.length > 3) return;
        } else {
          return;
        }
        break;

      default:
        break;
    }

    setState((prev) => {
      const newState = {
        ...prev,
        [name]: formattedValue || value,
        [`${name}Valid`]: validationResult,
      };

      newState.isValid = isFormValid(newState);

      if (onChange) {
        onChange(newState);
      }

      return newState;
    });
  };

  const handleInputFocus = (evt) => {
    setState((prev) => ({ ...prev, focus: evt.target.name }));
  };

  return (
    <div className="container-credit">
      <div className="card-preview">
        <Cards
          number={state.number}
          expiry={state.expiry}
          cvc={state.cvc}
          name={state.name}
          focused={state.focus}
        />
      </div>
      <form className="form-container" autoComplete="off">
        {/* Dummy hidden fields to trick browser's autofill */}
        <input style={{ display: "none" }} type="text" name="fakeName" />
        <input
          style={{ display: "none" }}
          type="password"
          name="fakePassword"
        />

        {/* <label className="label">Name</label> */}
        <div className="input-container">
          <div className="error-message">
            {state.nameValid.error ? state.nameValid.error : ""}
          </div>
          <input
            className="input-field"
            type="text" // changing from "name" to "text"
            name="name"
            placeholder="Card Holder Name"
            value={state.name}
            onChange={handleInputChange}
            onFocus={handleInputFocus}
            autoComplete="new-password"
          />
        </div>

        <div className="error-message">
          {state.numberValid.error ? state.numberValid.error : ""}
        </div>
        <input
          className="input-field"
          type="text"
          name="number"
          placeholder="Card Number"
          value={state.number}
          onChange={handleInputChange}
          onFocus={handleInputFocus}
          autoComplete="new-password"
        />

        <div className="error-message">
          {state.cvcValid.error ? state.cvcValid.error : ""}
        </div>
        <input
          className="input-field"
          type="text" // changing from "cvc" to "text"
          name="cvc"
          placeholder="Card CVC"
          value={state.cvc}
          onChange={handleInputChange}
          onFocus={handleInputFocus}
          autoComplete="new-password"
        />

        <div className="error-message">
          {state.expiryValid.error ? state.expiryValid.error : ""}
        </div>
        <input
          className="input-field"
          type="text" // changing from "expiry" to "text"
          name="expiry"
          placeholder="Card Expiration"
          value={tempExpiry}
          onFocus={handleInputFocus}
          onChange={(evt) => setTempExpiry(evt.target.value)}
          onBlur={handleInputChange}
          autoComplete="new-password"
        />
      </form>
    </div>
  );
};

export default CreditCardForm;
