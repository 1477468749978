import React from "react";
import { Nav, Button } from "react-bootstrap";
import { useLocation, Link, useNavigate } from "react-router-dom";
import axios from "axios";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlus,
  faEye,
  faListUl,
  faCircle,
  faBars,
} from "@fortawesome/free-solid-svg-icons";

const MenuSidebar = ({ isOpen, toggleSidebar, showButton }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  const userName = localStorage.getItem("name");

  // If token not set, logout
  if (!token) {
    navigate("/");
  }

  const handleLogout = async () => {
    try {
      const response = await axios.post(
        "https://lb.fshac.com:8443/api/logout",
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      console.log("Logout Response: ", response);
    } catch (err) {
      console.error("LOGOUT EREROR: ", err);
    }

    localStorage.clear();
    navigate("/");
    return;
  };

  const isActive = (path) => {
    return location.pathname === path;
  };

  const getAccessLevel = localStorage.getItem("group");
  let accessAdmin = false;
  if (getAccessLevel === "App_SecureCC_Office") accessAdmin = true;

  return (
    <>
      {showButton && (
        <div
          className={`fixed-topbar-container d-flex align-items-center d-xxl-none ${
            isOpen ? "open" : ""
          }`}
        >
          <button className="sidebar-toggle-btn" onClick={toggleSidebar}>
            <FontAwesomeIcon icon={faBars} />
          </button>
        </div>
      )}

      <Nav className={`sidebar flex-column ${isOpen ? "open" : "collapsed"}`}>
        <div className="p-3 text-center">
          <Link to="/dashboard">
            <img
              src="/FourSeasons_logo.png"
              alt="Four Seasons Logo"
              className="mx-2 py-4"
              width="200px"
            />
            <hr className="my-3" />
          </Link>
        </div>

        <Nav.Link
          as={Link}
          to="/dashboard"
          className={`nav-link-wrapper w-100 d-flex justify-content-between align-items-center text-decoration-none text-dark px-3 py-2 ${
            isActive("/dashboard") ? "active" : ""
          }`}
        >
          <div className="d-flex align-items-center">
            <FontAwesomeIcon icon={faPlus} className="me-3 icon-container" />
            <span className="sidebar-text-content">New Transaction</span>
          </div>
          {isActive("/dashboard") && (
            <FontAwesomeIcon icon={faCircle} className="activeIcon-custom" />
          )}
        </Nav.Link>

        {accessAdmin ? (
          <>
            <Nav.Link
              as={Link}
              to="/transfers"
              className={`nav-link-wrapper w-100 d-flex justify-content-between align-items-center text-decoration-none text-dark px-3 py-2 ${
                isActive("/transfers") ? "active" : ""
              }`}
            >
              <div className="d-flex align-items-center">
                <FontAwesomeIcon icon={faEye} className="me-3 icon-container" />
                <span className="sidebar-text-content">Transactions</span>
              </div>
              {isActive("/transfers") && (
                <FontAwesomeIcon
                  icon={faCircle}
                  className="activeIcon-custom"
                />
              )}
            </Nav.Link>

            <Nav.Link
              as={Link}
              to="/logs"
              className={`nav-link-wrapper w-100 d-flex justify-content-between align-items-center text-decoration-none text-dark px-3 py-2 ${
                isActive("/logs") ? "active" : ""
              }`}
            >
              <div className="d-flex align-items-center">
                <FontAwesomeIcon
                  icon={faListUl}
                  className="me-3 icon-container"
                />
                <span className="sidebar-text-content">Activity</span>
              </div>
              {isActive("/logs") && (
                <FontAwesomeIcon
                  icon={faCircle}
                  className="activeIcon-custom"
                />
              )}
            </Nav.Link>
          </>
        ) : (
          ""
        )}

        <div className="user-section">
          <div className="username">{userName}</div>
          <Button
            onClick={handleLogout}
            className="mt-auto mb-3 ml-3 btn-logout"
          >
            Logout
          </Button>
        </div>
      </Nav>
    </>
  );
};

export default MenuSidebar;
