// ToastContainer.js
import { Toast, ToastContainer } from "react-bootstrap";
import React, { useState, useCallback } from "react";
import ToastContext from "../ToastContext";

function ToastContain({ children }) {
  const [isToastVisible, setToastVisibility] = useState(false);
  const [toastText, setToastText] = useState("");
  const [toastClass, setToastClass] = useState("bg-success");
  const [toastBodyClass, setToastBodyClass] = useState("text-white");

  const showToast = useCallback((text, options = {}) => {
    setToastText(text);
    setToastClass(options.toastClass || "bg-success");
    setToastBodyClass(options.toastBodyClass || "text-white");
    setToastVisibility(true);
  }, []);

  return (
    <ToastContext.Provider value={showToast}>
      {children}
      <ToastContainer
        // className="p-3"
        style={{
          position: "fixed",
          top: 0,
          right: 0,
          margin: 20,
          zIndex: 99999,
        }}
      >
        <Toast
          onClose={() => setToastVisibility(false)}
          className={`${toastClass}`}
          show={isToastVisible}
          delay={3000}
          autohide
        >
          <Toast.Header className="d-flex justify-content-between">
            <strong className="mr-auto">Notification</strong>
          </Toast.Header>
          <Toast.Body className={`${toastBodyClass}`}>{toastText}</Toast.Body>
        </Toast>
      </ToastContainer>
    </ToastContext.Provider>
  );
}

export default ToastContain;
