import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  useContext,
} from "react";
import { useNavigate } from "react-router-dom";
import CreditCardForm from "../../components/CreditCardForm";
import { Form, Button, Row, Col, Container } from "react-bootstrap";
import Select from "react-select";
import "./dashboard.css";
import ToastContext from "../../components/ToastContext";
import axios from "axios";
import { debounce } from "lodash";

const CCFormLookup = {
  name: "nameOnCard",
  number: "ccNum",
  cvc: "ccCVC",
  expiry: "ccExpiry",
};

const stateAbbr = [
  "AL",
  "AK",
  "AZ",
  "AR",
  "CA",
  "CO",
  "CT",
  "DE",
  "FL",
  "GA",
  "HI",
  "ID",
  "IL",
  "IN",
  "IA",
  "KS",
  "KY",
  "LA",
  "ME",
  "MD",
  "MA",
  "MI",
  "MN",
  "MS",
  "MO",
  "MT",
  "NE",
  "NV",
  "NH",
  "NJ",
  "NM",
  "NY",
  "NC",
  "ND",
  "OH",
  "OK",
  "OR",
  "PA",
  "RI",
  "SC",
  "SD",
  "TN",
  "TX",
  "UT",
  "VT",
  "VA",
  "WA",
  "WV",
  "WI",
  "WY",
];

const customStyles = {
  menuList: (provided) => ({
    ...provided,
    maxHeight: 200,
    zIndex: 9999,
  }),
  menu: (provided) => ({
    ...provided,
    zIndex: 9999,
  }),
  container: (provided) => ({
    ...provided,
    width: "100%",
  }),
};

const confirmText = (text) => {
  if (!text) return { isValid: false, errorMsg: "Text is missing." };

  if (typeof text !== "string")
    return { isValid: false, errorMsg: "Input is not a string." };

  if (!/^[a-zA-Z0-9\s.,?!-#]+$/.test(text))
    return { isValid: false, errorMsg: "Text is not alphanumeric." };

  return { isValid: true, errorMsg: "" };
};

const confirmAmount = (amount) => {
  const convertedAmount = Number(amount);

  // Check if convertedAmount is a valid number and not in scientific notation
  if (
    isNaN(convertedAmount) ||
    typeof convertedAmount !== "number" ||
    /e/.test(convertedAmount.toString())
  )
    return { isValid: false, errorMsg: "Amount is not a valid number." };

  // Check if convertedAmount is a valid decimal to two places (like a currency value)
  if (!/^\d+(\.\d{1,2})?$/.test(convertedAmount.toString()))
    return {
      isValid: false,
      errorMsg: "Amount is not a valid USD currency value.",
    };

  // Limit the number to 1 million
  if (convertedAmount > 1000000)
    return {
      isValid: false,
      errorMsg: "Amount exceeds the limit of 1 million.",
    };

  return { isValid: true, errorMsg: "" };
};

const confirmNumber = (num) => {
  // Check if num is a number
  if (typeof num !== "number")
    return { isValid: false, errorMsg: "Input is not a number." };

  if (num.length === 0)
    return { isValid: false, errorMsg: "Please enter a value." };

  // Check if the number is finite (this excludes Infinity, -Infinity, and NaN)
  if (!isFinite(num))
    return { isValid: false, errorMsg: "Input is not a number." };

  // Check if the number is an integer
  if (num !== Math.floor(num))
    return { isValid: false, errorMsg: "Input is not an integer." };

  // If all checks pass
  return { isValid: true, errorMsg: "" };
};

const defaultFormData = {
  ccNum: "",
  nameOnCard: "",
  ccExpiry: "",
  ccCVC: "",

  address: "",
  city: "",
  state: "",
  zip: "",
  membershipNum: "",
  chargeAmount: "",
  customerAccountNum: "",
  customerClass: "",
  notes: "",
};

const defaultErrorData = {
  isValid: false,
  cvcValid: { valid: false },
  expiryValid: { valid: true },
  nameValid: { valid: false },
  numberValid: {
    valid: false,
    error: "",
  },
  focus: "",
};

const Dashboard = () => {
  const showToast = useContext(ToastContext);
  const [formData, setFormData] = useState(defaultFormData);
  const [errors, setErrors] = useState(defaultErrorData);
  const [shouldClearForm, setShouldClearForm] = useState(false);

  const zipRef = useRef(null);
  const chargeAmountRef = useRef(null);
  const customerAccountNumRef = useRef(null);
  const membershipNumRef = useRef(null);

  useEffect(() => {
    const preventScroll = (e) => e.preventDefault();

    const zipElem = zipRef.current;
    const chargeAmountElem = chargeAmountRef.current;
    const customerAccountNumElem = customerAccountNumRef.current;
    const membershipNumElem = membershipNumRef.current;

    zipElem &&
      zipElem.addEventListener("mousewheel", preventScroll, { passive: false });
    chargeAmountElem &&
      chargeAmountElem.addEventListener("mousewheel", preventScroll, {
        passive: false,
      });
    customerAccountNumElem &&
      customerAccountNumElem.addEventListener("mousewheel", preventScroll, {
        passive: false,
      });
    membershipNumElem &&
      membershipNumElem.addEventListener("mousewheel", preventScroll, {
        passive: false,
      });

    // Cleanup to remove event listeners on unmount
    return () => {
      zipElem && zipElem.removeEventListener("mousewheel", preventScroll);
      chargeAmountElem &&
        chargeAmountElem.removeEventListener("mousewheel", preventScroll);
      customerAccountNumElem &&
        customerAccountNumElem.removeEventListener("mousewheel", preventScroll);
      membershipNumElem &&
        membershipNumElem.removeEventListener("mousewheel", preventScroll);
    };
  }, []);

  const validateData = (name, value) => {
    let validationResults = { isValid: true, errorMsg: "" }; // default

    // Dollar amount
    if (name === "chargeAmount") {
      validationResults = confirmAmount(value);
    }

    // No decimals | "membershipNum"
    else if (["zip", "customerAccountNum"].includes(name)) {
      validationResults = confirmNumber(parseInt(value, 10));
    }

    // Contains text // ["address", "city", "state", "customerClass"]
    else if (["address", "city", "state", "customerClass"].includes(name)) {
      validationResults = confirmText(value);
    } else if (Object.values(CCFormLookup).includes(name)) {
      // Check the errors var
      validationResults = {
        isValid: errors.isValid,
        errorMsg: "Invalid card details.",
      };
    } else if (name == "notes") {
      // Check if empty first
      if (!value.trim()) {
        validationResults = {
          isValid: true,
          errorMsg: "", // No error since it's valid
        };
      } else {
        // If not empty, then check against confirmText()
        validationResults = confirmText(value);
      }
    }

    return validationResults;
  };
  console.log("ERRORS: ", errors);

  const validateForm = (data) => {
    const validationErrors = {};

    // Loop through all formData keys and validate them
    for (const [name, value] of Object.entries(data)) {
      const validationResult = validateData(name, value);

      if (!validationResult.isValid) {
        validationErrors[name] = validationResult.errorMsg;
      }
    }

    return validationErrors;
  };

  const handleUpdateState = (data, errors = null) => {
    setFormData((prev) => ({
      ...prev,
      ...data,
    }));

    if (!errors) return;

    setErrors((prev) => ({
      ...prev,
      ...errors,
    }));
  };

  const handleCardDataChange = (cardData) => {
    // Reformat the cardData
    let errorData;

    const saveData = Object.entries(cardData).reduce((acc, [key, value]) => {
      const ccKey = CCFormLookup[key];
      const errorKey = errors?.[key];

      if (errorKey !== null) {
        errorData = {
          ...errorData,
          [key]: value,
        };
      }

      if (!ccKey) return acc;

      return {
        ...acc,
        [ccKey]: value,
      };
    }, {});

    handleUpdateState(saveData, errorData);
  };

  const debouncedHandleCardDataChange = debounce(handleCardDataChange, 200);
  console.log("Form Data: ", formData);

  const handleChange = (event) => {
    const { name, value } = event.target;

    // If the field is empty, allow deletion.
    if (value) {
      // Prevent input
      switch (name) {
        case "address":
          if (!/^[a-zA-Z0-9\# ]+$/.test(value)) return;
          if (value.length > 75) return;
          break;

        case "city":
          if (value.length > 50) return;
          break;

        case "zip":
          if (value.length < 6) {
            if (!/^(?=.*\d)[\d ]+$/.test(value)) return;
          } else {
            return;
          }
          break;

        case "membershipNum":
        case "customerAccountNum":
          if (!/^\d+$/.test(value)) return;
          if (value.length > 25) return;
          break;

        case "chargeAmount":
          if (!/^(?:0|[1-9]\d{0,6})(?:\.\d{0,2})?$/.test(value)) return;
          if (parseFloat(value) > 1000000) return;
          break;
        case "customerClass":
          if (value === "Home Depot") {
            handleUpdateState({ membershipNum: "" });
          }
          break;

        case "notes":
          if (!/^[a-zA-Z0-9\s.,?!]+$/.test(value)) return;
          if (value.length > 100) return;
          break;

        default:
          break;
      }
    }

    let validationResults = { isValid: true, errorMsg: "" };
    validationResults = validateData(name, value);

    const saveData = {
      [name]: value,
    };

    const errors = {
      [name]: validationResults.errorMsg,
    };

    handleUpdateState(saveData, errors);
  };

  const handleSubmit = useCallback(
    (event) => {
      event.preventDefault();

      const validationErrors = validateForm(formData);

      // Check the "membershipNum" if costco
      if (formData.customerClass == "Costco") {
        // Verify not empty
        const costcoVerify = confirmText(formData.membershipNum);
        if (costcoVerify.errorMsg !== "") {
          validationErrors.membershipNum = costcoVerify.errorMsg;
        }
      }

      console.log("VALIDATION: ", validationErrors);

      // If validation errors exist
      if (Object.keys(validationErrors).length > 0) {
        setErrors((prev) => ({
          ...prev,
          ...validationErrors,
        }));

        showToast(
          "There were errors in your submission. Please check and correct them.",
          {
            toastClass: "bg-danger",
            toastBodyClass: "text-white",
            position: "bottom-end",
          }
        );

        // const jsonString = JSON.stringify(validationErrors);
        // const truncatedJsonString = jsonString.substring(0, 2040);

        // Try to submit log
        // handleLogs({
        //   status: 499,
        //   function: "handleSubmit(useCallback)",
        //   activity: truncatedJsonString,
        // });

        return;
      }

      // Submit to API
      handleAPI(formData);
    },
    [formData]
  );

  const handleLogs = (logData) => {
    const token = localStorage.getItem("token");

    axios
      .post(
        `https://lb.fshac.com:8443/api/log`,
        {
          ...logData,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then()
      .catch((err) => {
        showToast(`Log Submit Failed: ${err.toString()}`, {
          toastClass: "bg-danger",
          toastBodyClass: "text-white",
          position: "top-right",
        });

        console.error(err);
      });
  };

  const handleAPI = (formData) => {
    showToast("Transaction Submitted. Do Not Refresh.", {
      toastClass: "bg-warning",
      toastBodyClass: "text-dark",
      position: "top-right",
    });

    const token = localStorage.getItem("token");

    console.log("FORM SUBMIT HERE: ", formData);

    if (formData.ccExpiry == "") {
      formData.ccExpiry = "00/0000";
    }

    axios
      .post(
        `https://lb.fshac.com:8443/api/submit`,
        {
          ...formData,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        console.log("REsponse::: ", res);
        showToast("Transaction Submitted Successfully", {
          toastClass: "bg-success",
          toastBodyClass: "text-white",
          position: "top-right",
        });

        // Clear the data
        console.log("Clearing the data.");
        handleUpdateState(defaultFormData, defaultErrorData);
        handleClearForm();
      })
      .catch((err) => {
        showToast("Transaction Failed", {
          toastClass: "bg-danger",
          toastBodyClass: "text-white",
          position: "top-right",
        });

        console.error(err);
      });
  };

  const handleClearForm = () => {
    setShouldClearForm(true);
    // Reset it back after a moment to avoid re-clearing
    setTimeout(() => {
      setShouldClearForm(false);
    }, 50);
  };

  const getSelectValues = (select) => {
    if (select == "state") {
      return stateAbbr.map((state) => ({
        value: state,
        label: state,
      }));
    }

    if (select == "customerClass") {
      return ["Costco", "Home Depot"].map((memType) => ({
        value: memType,
        label: memType,
      }));
    }
  };

  const getSelectValue = (simpleValue, select) => {
    return getSelectValues(select).filter((val) => val.value === simpleValue);
  };

  return (
    <div className="d-flex align-items-center justify-content-center custom-div-dashboard">
      <Container fluid>
        <Row className="align-items-center justify-content-center">
          <Col xl={5} style={{ margin: "0.5rem 0" }}>
            <CreditCardForm
              clearForm={shouldClearForm}
              onChange={debouncedHandleCardDataChange}
            />
          </Col>
          <Col xl>
            <Form
              onSubmit={handleSubmit}
              autoComplete="new-password"
              style={{ margin: "0.5rem 0" }}
            >
              <Form.Group
                controlId="formAddress"
                className="input-custom-contain"
              >
                <Form.Floating>
                  <Form.Control
                    autoComplete="new-password"
                    type="text"
                    name="address"
                    value={formData.address}
                    placeholder="Billing Address"
                    onChange={handleChange}
                    isInvalid={!!errors.address}
                  />
                  <Form.Label>Billing Address</Form.Label>
                  <Form.Control.Feedback type="invalid">
                    {errors.address}
                  </Form.Control.Feedback>
                </Form.Floating>
              </Form.Group>

              <Row>
                <Col sm={8}>
                  <Form.Group
                    controlId="formCity"
                    className="input-custom-contain"
                  >
                    <Form.Floating>
                      <Form.Control
                        autoComplete="new-password"
                        type="text"
                        name="city"
                        placeholder="City"
                        value={formData.city}
                        onChange={handleChange}
                        isInvalid={!!errors.city}
                      />
                      <Form.Label>City</Form.Label>
                      <Form.Control.Feedback type="invalid">
                        {errors.city}
                      </Form.Control.Feedback>
                    </Form.Floating>
                  </Form.Group>
                </Col>
                <Col
                  sm
                  className="d-flex justify-content-center align-items-center flex-1"
                >
                  <Form.Group
                    controlId="formState"
                    className="input-custom-contain w-100"
                  >
                    <Select
                      styles={customStyles}
                      value={getSelectValue(formData.state, "state")}
                      options={getSelectValues("state")}
                      onChange={(selectedOption) =>
                        handleChange({
                          target: {
                            name: "state",
                            value: selectedOption.value,
                          },
                        })
                      }
                      className="basic-single"
                      classNamePrefix="select"
                      name="state"
                      placeholder="State"
                    />
                    {errors.state && (
                      <div className="invalid-feedback d-block">
                        {errors.state}
                      </div>
                    )}
                  </Form.Group>
                </Col>
              </Row>

              <Form.Group controlId="formZip" className="input-custom-contain">
                <Form.Floating>
                  <Form.Control
                    ref={zipRef}
                    autoComplete="new-password"
                    type="number"
                    name="zip"
                    step="1"
                    min="10000"
                    max="99999"
                    placeholder="Zip"
                    value={formData.zip}
                    onChange={handleChange}
                    isInvalid={!!errors.zip}
                  />
                  <Form.Label>Zip</Form.Label>
                  <Form.Control.Feedback type="invalid">
                    {errors.zip}
                  </Form.Control.Feedback>
                </Form.Floating>
              </Form.Group>

              <Form.Group
                controlId="formChargeAmount"
                className="input-custom-contain"
              >
                <Form.Floating>
                  <Form.Control
                    ref={chargeAmountRef}
                    autoComplete="new-password"
                    type="number"
                    name="chargeAmount"
                    step=".01"
                    min="0"
                    max="9999999"
                    placeholder="Amount to Charge"
                    value={formData.chargeAmount}
                    onChange={handleChange}
                    isInvalid={!!errors.chargeAmount}
                  />
                  <Form.Label>Amount to Charge</Form.Label>
                  <Form.Control.Feedback type="invalid">
                    {errors.chargeAmount}
                  </Form.Control.Feedback>
                </Form.Floating>
              </Form.Group>

              <Form.Group
                controlId="formCustomerAccountNum"
                className="input-custom-contain"
              >
                <Form.Floating>
                  <Form.Control
                    ref={customerAccountNumRef}
                    autoComplete="new-password"
                    type="number"
                    name="customerAccountNum"
                    step=".01"
                    min="0"
                    max="9999999"
                    placeholder="VAI Customer Account Number"
                    value={formData.customerAccountNum}
                    onChange={handleChange}
                    isInvalid={!!errors.customerAccountNum}
                  />
                  <Form.Label>VAI Customer Account Number</Form.Label>
                  <Form.Control.Feedback type="invalid">
                    {errors.customerAccountNum}
                  </Form.Control.Feedback>
                </Form.Floating>
              </Form.Group>

              <Form.Group
                controlId="formCustomerClass"
                className="input-custom-contain"
              >
                <Select
                  styles={customStyles}
                  value={getSelectValue(
                    formData.customerClass,
                    "customerClass"
                  )}
                  options={getSelectValues("customerClass")}
                  onChange={(selectedOption) =>
                    handleChange({
                      target: {
                        name: "customerClass",
                        value: selectedOption.value,
                      },
                    })
                  }
                  className="basic-single"
                  classNamePrefix="select"
                  name="customerClass"
                  placeholder="Membership Type"
                />
                {errors.state && (
                  <div className="invalid-feedback d-block">
                    {errors.customerClass}
                  </div>
                )}
              </Form.Group>

              {/* <Form.Group
                controlId="formMembershipNum"
                className="input-custom-contain"
              >
                <Form.Floating>
                  <Form.Control
                    ref={membershipNumRef}
                    autoComplete="new-password"
                    type="text"
                    name="membershipNum"
                    placeholder="Costco Membership Number"
                    value={formData.membershipNum}
                    onChange={handleChange}
                    isInvalid={!!errors.membershipNum}
                    disabled={formData.customerClass !== "Costco"}
                  />
                  <Form.Label>Costco Membership Number</Form.Label>
                  <Form.Control.Feedback type="invalid">
                    {errors.membershipNum}
                  </Form.Control.Feedback>
                </Form.Floating>
              </Form.Group> */}

              <Form.Group
                controlId="formMembershipNum"
                className={`input-custom-contain input-custom-contain-smooth ${
                  formData.customerClass === "Costco" ? "show" : ""
                }`}
              >
                <Form.Floating>
                  <Form.Control
                    ref={membershipNumRef}
                    autoComplete="new-password"
                    type="text"
                    name="membershipNum"
                    placeholder="Costco Membership Number"
                    value={formData.membershipNum}
                    onChange={handleChange}
                    isInvalid={!!errors.membershipNum}
                  />
                  <Form.Label>Costco Membership Number</Form.Label>
                  <Form.Control.Feedback type="invalid">
                    {errors.membershipNum}
                  </Form.Control.Feedback>
                </Form.Floating>
              </Form.Group>

              <Form.Group
                controlId="formNotes"
                className="input-custom-contain"
                style={{ marginBottom: 25 }}
              >
                <Form.Floating>
                  <Form.Control
                    autoComplete="new-password"
                    type="text"
                    name="notes"
                    placeholder="Costco Membership Number"
                    value={formData.notes}
                    onChange={handleChange}
                    isInvalid={!!errors.notes}
                  />
                  <Form.Label>Notes</Form.Label>
                  <Form.Control.Feedback type="invalid">
                    {errors.notes}
                  </Form.Control.Feedback>
                </Form.Floating>
              </Form.Group>

              <div className="d-flex justify-content-center align-items-center">
                <Button variant="primary" type="submit">
                  Submit
                </Button>
              </div>
            </Form>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Dashboard;
