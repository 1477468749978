import React, { useEffect, useState, useContext } from "react";
import axios from "axios";
import { Table, Button, Modal, Toast } from "react-bootstrap";
import Cards from "react-credit-cards-2";
// import "react-credit-cards-2/dist/es/styles-compiled.css";
// import "react-credit-cards/es/styles-compiled.css";
import { utcToZonedTime, format } from "date-fns-tz";
import copy from "copy-to-clipboard";
import ToastContext from "../../components/ToastContext";
import "./transfers.css";
import { useNavigate, useParams } from "react-router-dom";
import MUIDataTable from "mui-datatables";
import IconButton from "@mui/material/IconButton";
import RefreshIcon from "@mui/icons-material/Refresh";

const baseUrl = "https://lb.fshac.com:8443/api/";

const cstFormat = "MM/dd/yyyy hh:mm:ss a";
const cstTimeZone = "America/Chicago"; // Central Standard Time
const formatDate = (date) => {
  const cstDate = utcToZonedTime(date, cstTimeZone);
  const formattedDate = format(cstDate, cstFormat, { timeZone: cstTimeZone });

  return formattedDate;
};

const CardDetailModal = ({
  selectedInfo,
  modalShow,
  setModalShow,
  setShowConfirm,
  showToast,
}) => {
  const copyToClipboard = (text, field = "field") => {
    copy(text);
    // showToast(`Copied ${field} to clipboard`, { position: "bottom-end" });
    showToast(`Copied ${field} to clipboard`, {
      position: "bottom-end",
    });
  };

  const createTableRow = (title, value, description = title, key) => (
    <tr key={key}>
      <th>{title}</th>
      <td
        className="cursor-pointer"
        onClick={() => copyToClipboard(value, description)}
      >
        {value || `No ${description.toLowerCase()} added`}
      </td>
    </tr>
  );

  const cardDetails = [
    ["Card Number", selectedInfo.ccNum],
    ["Name on Card", selectedInfo.nameOnCard],
    ["Expiry Date", selectedInfo.ccExpiry],
    ["CVC", selectedInfo.ccCVC],
  ];

  const personalDetails = [
    ["Submitted By", selectedInfo.userName],
    ["Submitted At", formatDate(selectedInfo.created_at)],
    ["Name on Card", selectedInfo.nameOnCard],
    ["Address", selectedInfo.address],
    ["City", selectedInfo.city],
    ["State", selectedInfo.state],
    ["Zip Code", selectedInfo.zip],
  ];

  const membershipDetails = [
    ["Membership Number", selectedInfo.membershipNum],
    ["Charge Amount", selectedInfo.chargeAmount],
    ["Customer Account Number", selectedInfo.customerAccountNum],
    ["Completed By", selectedInfo.completed_by],
    ["Completion Status", selectedInfo.completed],
    ["Completion Time", selectedInfo.completed_at],
    ["Notes", selectedInfo.notes],
  ];

  return (
    <Modal
      show={modalShow}
      onHide={() => setModalShow(false)}
      backdrop="static"
      keyboard={false}
      size="xl"
      style={{ zIndex: 9998 }}
    >
      <Modal.Header className="d-flex justify-content-between">
        <Modal.Title>Card Info</Modal.Title>
        <div>
          <i>Click on a cell to copy the data</i>
        </div>
      </Modal.Header>
      <Modal.Body>
        <div className="d-flex justify-content-center align-items-center">
          <div className="m-2">
            <Cards
              number={selectedInfo.ccNum}
              name={selectedInfo.nameOnCard}
              expiry={selectedInfo.ccExpiry}
              cvc={selectedInfo.ccCVC}
              focused={null}
            />
          </div>
          <div className="m-2">
            <Table responsive="sm" bordered className="mb-0">
              <tbody>
                {cardDetails.map(([title, value, desc], index) =>
                  createTableRow(title, value, desc, index)
                )}
              </tbody>
            </Table>
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-md-6">
            <Table responsive="sm" bordered>
              <tbody>
                {personalDetails.map(([title, value, desc], index) =>
                  createTableRow(title, value, desc, index)
                )}
              </tbody>
            </Table>
          </div>
          <div className="col-md-6">
            <Table responsive="sm" bordered>
              <tbody>
                {membershipDetails.map(([title, value, desc], index) =>
                  createTableRow(title, value, desc, index)
                )}
              </tbody>
            </Table>
          </div>
        </div>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="secondary" onClick={() => setModalShow(false)}>
          Close
        </Button>
        <Button
          variant="primary"
          onClick={() => {
            setModalShow(false);
            setShowConfirm(true);
          }}
        >
          Mark as Completed
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const CustomToolbar = (props) => {
  return (
    <IconButton onClick={props.refreshTransfers}>
      <RefreshIcon />
    </IconButton>
  );
};

const Transfers = () => {
  const navigate = useNavigate();
  const params = useParams();
  const id = params.id;

  const [cardInfos, setCardInfos] = useState([]);
  const [selectedInfo, setSelectedInfo] = useState(null);
  const [modalShow, setModalShow] = useState(false);
  const showToast = useContext(ToastContext);
  const [showConfirm, setShowConfirm] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingCard, setIsLoadingCard] = useState(false);

  const token = localStorage.getItem("token");

  const axiosConfig = {
    baseURL: baseUrl,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const axiosGet = (url) => axios.get(url, axiosConfig);
  const axiosPost = (url, data) => axios.post(url, data, axiosConfig);

  useEffect(() => {
    setIsLoading(true);
    axiosGet("/cardinfos")
      .then((res) => {
        setCardInfos(res.data);
        setIsLoading(false);
      })
      .catch((err) => {
        setCardInfos([]);
        setIsLoading(false);
      });
  }, []);

  useEffect(() => {
    if (id) {
      // Simulate row click
      handleRowClick(id);
    }
  }, [!isLoading]);

  const handleRowClick = (id) => {
    setIsLoadingCard(true);

    axiosGet(`/cardinfo/${id}`)
      .then((res) => {
        console.log(res.data);
        setSelectedInfo(res.data);
        setModalShow(true);
        setIsLoadingCard(false);
        navigate(`/transfers/${id}`);
      })
      .catch((err) => {
        setModalShow(false);
        setIsLoadingCard(false);

        showToast("Failed to Fetch Transaction", {
          toastClass: "bg-danger",
          toastBodyClass: "text-white",
          position: "top-right",
        });

        console.error(err);
      });
  };

  const refreshTransfers = () => {
    setIsLoading(true); // Set loading to true as we start fetching
    navigate(`/transfers`);
    axiosGet("/cardinfos")
      .then((res) => {
        setCardInfos(res.data);
        setIsLoading(false);

        showToast("Data Refreshed.", {
          toastClass: "bg-success",
          toastBodyClass: "text-white",
          position: "top-right",
        });
      })
      .catch((err) => {
        showToast("Failed to Fetch Data", {
          toastClass: "bg-danger",
          toastBodyClass: "text-white",
          position: "top-right",
        });
      });
  };

  const handleComplete = (id) => {
    axiosPost(`/cardinfo/${id}/deletecard`, {
      completed: true,
    })
      .then((res) => {
        setCardInfos(
          cardInfos.map((info) =>
            info.id === id ? { ...info, completed: true } : info
          )
        );

        // Notify
        showToast("Transaction Updated Successfully", {
          toastClass: "bg-success",
          toastBodyClass: "text-white",
          position: "top-right",
        });

        // Refresh the info
        refreshTransfers();
      })
      .catch((err) => {
        // Notify
        showToast(
          "An error occurred. Please try logging out and logging back in.",
          {
            toastClass: "bg-danger",
            toastBodyClass: "text-white",
            position: "bottom-end",
          }
        );

        console.error(err);
      });
  };

  const columns = [
    {
      name: "createdAt",
      label: "Time Submitted",
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value) => formatDate(value),
      },
    },
    {
      name: "user_name",
      label: "Submitted By",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "customerClass",
      label: "Class",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "customerAccountNum",
      label: "VAI Number",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "nameOnCard",
      label: "Name on Card",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "chargeAmount",
      label: "Amount",
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value) => {
          return `$${parseFloat(value).toLocaleString("en-US", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}`;
        },
      },
    },
    {
      name: "completed",
      label: "Completed",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => (value ? "Yes" : "No"),
      },
    },
  ];

  const muiOptions = {
    responsive: "standard",
    selectableRows: "none",
    viewColumns: false,
    print: false,
    sortOrder: {
      name: "createdAt",
      direction: "asc",
    },
    setRowProps: () => ({
      className: "cursor-pointer",
    }),
    onRowClick: (rowData, rowMeta) => {
      const info = cardInfos[rowMeta.dataIndex];
      handleRowClick(info.id);
    },
    customToolbar: () => {
      return <CustomToolbar refreshTransfers={refreshTransfers} />;
    },
  };

  return (
    <>
      {isLoading ? (
        <div>Loading...</div>
      ) : (
        <div className="mui-custom-table-contain">
          <MUIDataTable
            className="full-width-table"
            title={"Card Information"}
            data={cardInfos}
            columns={columns}
            options={muiOptions}
          />
        </div>
      )}

      <div>
        {isLoadingCard ? (
          <Modal show={true} onHide={() => setModalShow(false)}>
            <Modal.Body>Loading...</Modal.Body>
          </Modal>
        ) : (
          selectedInfo && (
            <CardDetailModal
              selectedInfo={selectedInfo}
              showToast={showToast}
              modalShow={modalShow}
              setModalShow={setModalShow}
              setShowConfirm={setShowConfirm}
            />
          )
        )}
      </div>

      <Modal
        show={showConfirm}
        onHide={() => setShowConfirm(false)}
        style={{ zIndex: 99999 }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Confirm Completion</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to mark this as completed? This will remove all
          credit card data from the database and is not reversable.
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              setModalShow(true);
              setShowConfirm(false);
            }}
          >
            Cancel
          </Button>
          <Button
            variant="danger"
            onClick={() => {
              handleComplete(selectedInfo.id);
              setShowConfirm(false);
            }}
          >
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Transfers;
