import { createRoot } from "react-dom/client";
import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import App from "./App";
import "./index.css";

const root = document.getElementById("root");
if (root !== null) {
  createRoot(root).render(
    <Router>
      <App />
    </Router>
  );
}
