import React from "react";
import MenuSidebar from "./components/MenuSidebar";
import MenuTopbar from "./components/MenuTopbar";
import { useSidebar } from "./hooks/useSidebar";
import "./layout.css";

const Layout = ({ children }) => {
  const { isOpen, showButton, toggleSidebar, handleOutsideClick } =
    useSidebar();

  const padding = {
    true: `${showButton ? "65px" : "10px"} 10px 2rem 265px`,
    false: `${showButton ? "65px" : "10px"} 10px 2rem 10px`,
  };

  console.log("Showing: ", isOpen);

  return (
    <div className="dashboard" onClick={handleOutsideClick}>
      <MenuSidebar
        isOpen={isOpen}
        toggleSidebar={toggleSidebar}
        showButton={showButton}
      />
      <div
        className="content"
        style={{ padding: padding[isOpen], minHeight: "100vh" }}
      >
        {children}
      </div>
    </div>
  );
};

export default Layout;
