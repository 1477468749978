import { useState, useEffect } from "react";
import { Navigate } from "react-router-dom";
import axios from "axios";

export default function ProtectedRoute({ children }) {
  const [isLoading, setIsLoading] = useState(true);
  const [isValidToken, setIsValidToken] = useState(false);
  const token = localStorage.getItem("token");

  useEffect(() => {
    // Function to verify the token
    const verifyToken = async () => {
      console.log("Validating token...");
      try {
        // Set up axios defaults for Authorization header
        axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;

        const response = await axios.get(
          "https://lb.fshac.com:8443/api/verify-token"
        ); // Adjust the URL if necessary

        if (response.status === 200) {
          setIsValidToken(true);
        }
      } catch (error) {
        console.error("Token verification failed:", error);
        localStorage.removeItem("token"); // Optionally remove the invalid token
      } finally {
        setIsLoading(false);
      }
    };

    // If there's a token, initiate the first token verification
    if (token) {
      verifyToken();

      // Set an interval to verify the token every 15 minutes
      const intervalId = setInterval(verifyToken, 15 * 60 * 1000);

      // Clear the interval when the component is unmounted
      return () => clearInterval(intervalId);
    } else {
      setIsLoading(false);
    }
  }, [token]);

  if (isLoading) {
    return <div></div>; // Or a loading spinner component
  }

  if (isValidToken) {
    return children;
  } else {
    return <Navigate to="/login" state={{ from: window.location.pathname }} />;
  }
}
