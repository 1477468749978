export const getFormattedExpiry = (value) => {
  console.log("Expiry: ", value);

  if (!value) return null;

  const cleanedValue = value.replace(/[^\d/-]/g, ""); // Keep only digits and separators

  let components = cleanedValue.split(/[-/]/); // Split by both potential separators

  // Handling the edge case for 3 digits
  if (components.length === 1 && cleanedValue.length === 3) {
    components = [cleanedValue.substring(0, 1), cleanedValue.substring(1, 3)];
  }

  // Handling the edge case for 5 digits
  if (components.length === 1 && cleanedValue.length === 5) {
    if (cleanedValue.startsWith("0") || cleanedValue.startsWith("1")) {
      components = [cleanedValue.substring(0, 2), cleanedValue.substring(2)];
    } else {
      components = [cleanedValue.substring(0, 1), cleanedValue.substring(1)];
    }
  }

  // Handling the standard case for 4 or 6 digits
  else if (
    components.length === 1 &&
    (cleanedValue.length === 4 || cleanedValue.length === 6)
  ) {
    let splitIndex = cleanedValue.length === 6 ? 2 : cleanedValue.length - 2;
    components = [
      cleanedValue.substring(0, splitIndex),
      cleanedValue.substring(splitIndex),
    ];
  }

  let month = components[0];
  let year = components.length > 1 ? components[1] : "";

  if (parseInt(month, 10) > 12) {
    month = "12";
  }

  // Ensure the month is always two digits
  if (month.length === 1) {
    month = "0" + month;
  }

  // If year length is 2, assume it's 2000s
  if (year.length === 2) {
    year = "20" + year;
  }

  // If the year length is more than 4 characters
  if (year.length > 4) {
    year = year.substring(0, 4);
  }

  console.log("Formatted Expiry: ", `${month}/${year}`);

  return `${month}/${year}`;
};

export const getFormattedCreditCardNumber = (value) => {
  console.log("Value here: ", value);
  let valueCopy = JSON.parse(JSON.stringify(value));

  if (valueCopy.startsWith("3")) {
    // If it's an Amex card, format as "4 6 5"
    return valueCopy.replace(/(\d{4})(\d{6})(\d{5})/, "$1 $2 $3");
  }
  // Else, format as "4 4 4 4"
  const formatted = valueCopy.replace(
    /(\d{4})(\d{4})(\d{4})(\d{4})/,
    "$1 $2 $3 $4"
  );
  console.log("Formatted CC Num: ", formatted);
  return formatted;
};
