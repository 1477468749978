import React from "react";
import ReactDOM from "react-dom";
import { Routes, Route } from "react-router-dom";
import Layout from "./Layout";

import Login from "./pages/Login";
import Dashboard from "./pages/Dashboard";
import Transfers from "./pages/Transfers";
import Logs from "./pages/Logs";
import "bootstrap/dist/css/bootstrap.css";
import ProtectedRoute from "./components/ProtectedRoute";

import ToastContain from "./components/ToastContain";

function App() {
  return (
    <ToastContain>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/" element={<Login />} />
        <Route
          path="/dashboard"
          element={
            <ProtectedRoute>
              <Layout>
                <Dashboard />
              </Layout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/transfers"
          element={
            <ProtectedRoute>
              <Layout>
                <Transfers />
              </Layout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/transfers/:id"
          element={
            <ProtectedRoute>
              <Layout>
                <Transfers />
              </Layout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/logs"
          element={
            <ProtectedRoute>
              <Layout>
                <Logs />
              </Layout>
            </ProtectedRoute>
          }
        />
      </Routes>
    </ToastContain>
  );
}

export default App;
