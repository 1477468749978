import { useState, useEffect } from "react";

export function useSidebar() {
  const [isOpen, setIsOpen] = useState(window.innerWidth > 1400);
  const [showButton, setShowButton] = useState(window.innerWidth <= 1400);

  useEffect(() => {
    const handleResize = () => {
      setIsOpen(window.innerWidth > 1400);
      setShowButton(window.innerWidth <= 1400);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  const handleOutsideClick = (e) => {
    if (window.innerWidth < 1400 && isOpen && e.target.id !== "sidebar") {
      setIsOpen(false);
    }
  };

  return {
    isOpen,
    showButton,
    toggleSidebar,
    handleOutsideClick,
  };
}
